import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

// hooks
import { useUser } from '../hooks';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

interface ClinicProfile {
    id: number;
    provider_type: 'clinic';
    name: string;
    description: string;
    picture: string | null;
    phone_number: string;
    location: Location;
    doctors: number[];
    in_house_services: any[];
    created_at: string;
    updated_at: string;
}

/**
 * Private Route forces authorization before the route can be accessed
 */
const PrivateRoute = ({ component: RouteComponent, roles }: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();
    const api = new APICore();

    
    // Check authentication status
    if (!api.isUserAuthenticated()) {
        return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
    }

    // Check if password reset is required
    const forcePasswordReset = localStorage.getItem('force_password_reset') === 'true';

    if (forcePasswordReset && location.pathname !== '/auth/password-reset') {
     
        return <Navigate to="/auth/password-reset" replace />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
